import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { FormDropDownList } from './panels/FormComponents';
import { requiredValidator } from '../helpers/Validators';
import { PURGE_NOTIFICATIONS, TOGGLE_SETTINGS, CHANGE_THEME } from '../../store/actions/navActions';
import { OnDemandIcon } from '../../components/helpers/IconUtils';
import { app_themes } from '../../config/Menu';
import { RootState } from '../../store/reducer/rootReducer';
import { IUserInfoProps } from '../services/GraphQLShared';
import { CommonResources } from '../../config/CommonResources';
import { Row, Col } from 'react-bootstrap';
import { ConfirmDialog } from './panels/ConfirmDialog';
import { LIGHT_MODE } from '../../config/Constants';

const mapState = (state: RootState) => ({
  settingsOpen: state.nav.settingsOpen,
  theme: state.nav.theme,
  profile: state.security.profile,
});
const mapDispatch = {
  toggleSettings: () => ({ type: TOGGLE_SETTINGS }),
  purgueNotifications: () => ({ type: PURGE_NOTIFICATIONS }),
  changeTheme: (value: any) => ({ type: CHANGE_THEME, value: value }),
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type ISettingsProps = PropsFromRedux & {
  onSignOut: () => void;
  isAuthenticated: boolean;
  user: IUserInfoProps;
};

const Settings = (props: ISettingsProps) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const onClickClose = (e) => {
    e.preventDefault();
    props.toggleSettings();
  };
  const handleSubmit = (dataItem: any) => {
    props.changeTheme(dataItem.theme.id);
    props.toggleSettings();
  };
  const handleConfirmOK = () => {
    setOpenConfirm(false);
    props.purgueNotifications();
  };
  const initialForm = {
    theme: app_themes.find((p) => p.id === props.theme) ?? LIGHT_MODE,
  };
  return (
    <>
      <aside className={`dialog mobile with-padding shadow ${props.settingsOpen === true ? 'open' : ''}`}>
        <div className="dialog-header no-border">
          <h4 className="title">{CommonResources.AccountSettings}</h4>
          <Button type="button" className="btn-close" fillMode={'flat'} icon={'close'} onClick={onClickClose} />
        </div>
        <div className="dialog-content">
          <div className="dialog-group">
            <Form
              initialValues={initialForm}
              onSubmit={handleSubmit}
              render={(formRenderProps) => (
                <FormElement>
                  <fieldset className={'k-form k-form-fieldset'}>
                    <Row className="settings-user">
                      <Col md={3} className="form-group">
                        {props.user.avatar ? (
                          <img src={props.user.avatar} alt={props.user.displayName} />
                        ) : (
                          <div className="no-user-image">
                            <OnDemandIcon icon="user" size="2x" />
                          </div>
                        )}
                      </Col>
                      <Col md={9}>
                        <small>{CommonResources.LoggedAs}</small>
                        <h4 className="mb-0">{props.user ? props.user.displayName : 'Stranger'}</h4>
                        {props.user && props.user.jobTitle && <p className="mb-1">{props.user.jobTitle}</p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          id={'theme'}
                          name={'theme'}
                          label={'Theme'}
                          textField="text"
                          dataItemKey="id"
                          component={FormDropDownList}
                          data={app_themes}
                          validator={requiredValidator}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <Button
                          type={'submit'}
                          themeColor="primary"
                          onClick={formRenderProps.onSubmit}
                          disabled={!formRenderProps.allowSubmit}
                          style={{ float: 'right' }}
                        >
                          {CommonResources.Button_Save}
                        </Button>
                        <Button
                          type="button"
                          fillMode={'flat'}
                          onClick={() => {
                            formRenderProps.onFormReset();
                            props.toggleSettings();
                          }}
                          style={{ float: 'right' }}
                        >
                          {CommonResources.Button_Cancel}
                        </Button>
                      </Col>
                    </Row>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </div>
        <div className="dialog-footer">
          <Button onClick={() => setOpenConfirm(true)} className="btn btn-icon-leading">
            <OnDemandIcon icon={['far', 'bell-slash']} fixedWidth={true} className="me-1" />
            {CommonResources.PurgeNotifications}
          </Button>
          <Button onClick={() => props.onSignOut()} className="btn btn-icon-leading">
            <OnDemandIcon icon="sign-out-alt" className="me-1" />
            {CommonResources.SignOut}
          </Button>
        </div>
      </aside>
      <ConfirmDialog
        title={CommonResources.Dialog_ConfirmTitle}
        message={CommonResources.Dialog_PurgeNotifications}
        description={CommonResources.Dialog_ConfirmClick}
        onClickOk={handleConfirmOK}
        onClickCancel={() => setOpenConfirm(false)}
        visible={openConfirm}
      />
    </>
  );
};
export default connector(Settings);
